export default function (comarca) {
  let window, title
  if (typeof comarca !== 'undefined' && comarca) {
    window = comarca.id ? `comarca.${comarca.id}` : 'comarca.new'
    title = 'Visualizar Comarca'
  } else {
    window = 'comarca.new'
    title = 'Nova Comarca'
  }

  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: title,
    width: '500',
    height: '60%',
    minWidth: '500px',
    minHeight: '500px',
    backdrop: true,
    clickOutside: false,
    windowClass: 'erp-window',
    contentClass: 'overflow-hidden',
    props: {
      id: comarca?.id
    }
  }, () => import('./Comarca'))
      .then((wid) => {
        console.log(wid)
        this.$uloc.window.listen(wid, {
          update: (wid, val) => {
            console.log('Atualizado: ', val)
            this.updateList(val)
          }
        })
      }) // return wid
}
